import React from "react"
import styled from '@emotion/styled'
import translation, {TranslatedLink} from "../utils/translation"
import LangSelection from "./langSelection"
import Facebook from "../../static/images/facebook.svg";
import Twitter from "../../static/images/twitter.svg";

const FooterWrapper = styled.footer`
  background: #fbfbfb;
`;

const FooterContainer = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  height: 66px;
  align-items: center;

  @media (max-width: 900px) {
    padding-left: 10px;
  }

  @media (max-width: 768px) {
    height: auto;
    flex-direction: column;
    justify-content: center;
  }
`;

const FooterLeftContent = styled.div`
  flex: 1;

  @media (max-width: 768px) {
    order: 2;
    padding-bottom: 10px;
  }

  @media (max-width: 560px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const FooterRightContent = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    order: 1;
    padding: 20px 0;
    flex-direction: column;
  }
`;

const Tab = styled(TranslatedLink)`
  font-size: 12px;
  color: #2f2f2f;
  margin-right: 32px;
  line-height: 1;

  &:hover {
    color: #f38f8f;
  }

  @media (max-width: 900px) {
    margin-right: 20px;
  }

  @media (max-width: 560px) {
    margin-right: 0;
    padding: 20px 0;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-bottom: 0;
`;

const CopyRight = styled.span`
  font-size: 12px;
  line-height: 2;
  color: #2f2f2f;
  margin-right: 32px;
  cursor: pointer;

  &:hover {
    color: #f38f8f;
  }

  @media (max-width: 900px) {
    margin-right: 20px;
  }

  @media (max-width: 560px) {
    margin-right: 0;
    padding: 20px 0;
    line-height: 1;
  }
`;

const SocialContainer = styled.div`
  display: flex;

  a {
    @media (max-width: 560px) {
      padding: 0 15px;
    }
  }
`;

class Footer extends React.Component {
  openLink = (type) => {
    if (type === 'fb') {
      typeof window !== 'undefined' && window.location.assign('https://www.facebook.com/nobrainwriting/');
    }
    if (type === 'tw') {
      typeof window !== 'undefined' && window.location.assign('https://twitter.com/nobrain_writing');
    } 
  }

  render() {
    return (<FooterWrapper>
      <FooterContainer>
        <FooterLeftContent>
          <CopyRight>&copy; 2019 {translation("title")}</CopyRight>
          <Tab to="/terms/">{translation("terms")}</Tab>
          <Tab to="/privacy/">{translation("privacyPolicy")}</Tab>
          {/* <Tab to="/pricing/">{translation("cookiePolicy")}</Tab> */}
          {/* <Tab to="/contact/">{translation("Sitemap")}</Tab> */}
        </FooterLeftContent>
        <FooterRightContent>
          <LangSelection/>
          <SocialContainer>
            {/* <Tab to="/features/"><Icon src={Instagram} alt="Follow us on Instagram" /></Tab> */}
            <Tab to="/#"><Icon onClick={() => this.openLink('fb')} src={Facebook} alt="Follow us on Facebook" /></Tab>
            <Tab to="/#"><Icon onClick={() => this.openLink('tw')} src={Twitter} alt="Follow us on Twitter" /></Tab>
          </SocialContainer>
        </FooterRightContent>
      </FooterContainer>
    </FooterWrapper>);
  }
}

export default Footer;
