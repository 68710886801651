import React from "react";
import styled from '@emotion/styled';
import HamburgerMenu from 'react-hamburger-menu';

import translate, {TranslatedLink} from "../utils/translation";

import favicon from "../../static/images/favicon.svg";

import Tab from "./common/tab";

const LogoLink = styled(TranslatedLink)`
  font-size: 15.6px;
  letter-spacing: 0.2px;
  display: flex;
  line-height: 20px;

  @media (max-width: 690px) {
    position: relative;
    z-index: 2;
  }
`;

const Shape = styled.img`
  height: 20px;
  margin: 0 10px -5px 0;
`;

const RowLayout = styled.div`
    display: flex;
    align-items: center;
    padding: 30px 0;
    max-width: 990px;
    margin: 0 auto;

    @media (max-width: 990px) {
      padding: 30px 20px;
    }
`;

const MenuContainer = styled.nav`
  margin-left: auto;

  @media (max-width: 690px) {
    display: flex;
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-image: linear-gradient(to top, #F8F0F0, #E3F0FB);
    flex-direction: column;
    padding: 70px 0 0;
    z-index: 1;
  }

  &.close {
    @media (max-width: 690px) {
      display: none;
    }
  }
`
const activeClassName = (activeIndex, currentIndex) => activeIndex === currentIndex ?
  "active" : "";

const Container = styled.header`
  position: absolute;
  width: 100%;
  left: 0;
  top: 30px;
  
  &.sticky {
    position: fixed;
    top: 0;
    background-color: #ffffff;
    z-index: 3;
  }
`;

const ToggleMenuContainer = styled.div`
  display: none;

  @media (max-width: 690px) {
    position: absolute;
    top: 24px;
    right: 25px;
    display: block;
    z-index: 2;
    cursor: pointer;
  }
`;

const MenuItem = styled(Tab)`
  @media (max-width: 690px) {
    padding: 20px 25px;
    font-size: 18px;
    line-height: 0.92;

    &:before {
      display: none;
    }
  }
`;

const SubMenu = styled(Tab)`
  display: none;
  @media (max-width: 690px) {
    display: block;
    padding: 20px 54px;
    font-size: 15px;
    line-height: 1.1;
  }
`;

export default class Header extends React.Component {

  constructor() {
    super();
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      sticky: false,
      menuOpen: false,
    };
  }

  handleScroll() {
    this.setState({sticky: document.body.getBoundingClientRect().top < -50});
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleMenuClick() {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }

  render() {
    const activeIndex = this.props.activeTabIndex;
    return <Container className={this.state.sticky && "sticky"}>
      <RowLayout>
        <LogoLink to="/">
          <Shape src={favicon}/>
          {translate("title")}
        </LogoLink>
        <ToggleMenuContainer>
          <HamburgerMenu
            isOpen={this.state.menuOpen}
            menuClicked={this.handleMenuClick.bind(this)}
            width={24}
            height={14}
            strokeWidth={3}
            rotate={0}
            color='black'
            borderRadius={10}
            animationDuration={0.5}
          />
        </ToggleMenuContainer>
        <MenuContainer className={!this.state.menuOpen ? 'close' : ''}>
          <MenuItem to="/" className={activeClassName(activeIndex, 0)} >{translate("headers.home")}</MenuItem>
          {/* <MenuItem to="/#features" className={activeClassName(activeIndex, 1)}>{translate("headers.features")}</MenuItem> */}
          <MenuItem to="/about/" className={activeClassName(activeIndex, 2)}>{translate("headers.aboutNBW")}</MenuItem>
          <SubMenu to="/about/">{translate("headers.whatIsNBW")}</SubMenu>
          <SubMenu to="/about/how-to-write">{translate("headers.howToWrite")}</SubMenu>
          <SubMenu to="/about/faqs">{translate("headers.faqs")}</SubMenu>
          <MenuItem to="/pricing/" className={activeClassName(activeIndex, 3)}>{translate("headers.pricing")}</MenuItem>
          <MenuItem to="/contact/" className={activeClassName(activeIndex, 4)}>{translate("headers.contact")}</MenuItem>
        </MenuContainer>
      </RowLayout>
    </Container>;
  }
}
