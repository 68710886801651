import React from "react";

import { changeLanguage } from "../utils/translation";
import styled from "@emotion/styled";

const languages = ["en", "de"];
export default class LangSelection extends React.Component {
  constructor() {
    super();
    // During Gatsby build time, no localStorage exist
    const isNode = typeof module !== 'undefined';
    this.state = {
      activeLanguage: isNode ? 'en' : localStorage['gatsby-intl-language']
    };
    this.select = this.select.bind(this);
  }

  select(event) {
    const lang = languages[event.target.value];
    changeLanguage(lang);
    this.setState({activeLanguage: lang});
  }

  render() {
    const LangContainer = styled.div`
      display: flex;
      margin-right: 30px;

      @media (max-width: 560px) {
        margin-right: 0;
        margin-bottom: 30px;
      }
    `;

    return (<LangContainer>
      {this.getLangButton(0)}
      {this.getLangButton(1)}
    </LangContainer>);
  }

  getLangButton(langIndex) {
    const LangButton = styled.button`
      background: none;
      height: 24px;
      border: 0;
      cursor: pointer;
      padding: 2px 12px;
      line-height: 1;
      font-size: 12px;

      + button {
        border-left: 1px solid #d8d8d8;
      }

      &.active, &:hover {
        color: #f38f8f
      }
    `;
    return <LangButton value={langIndex} className={this.state.activeLanguage === languages[langIndex] && "active"}
                       onClick={this.select}>{languages[langIndex].toUpperCase()}</LangButton>;
  }

  componentWillUnmount() {
    localStorage['gatsby-intl-language'] = 'en';
  }
}