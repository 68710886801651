import React from "react"
import Header from "./header"
import styled from '@emotion/styled'
import cloud from '../../static/images/cloud.svg';
import Footer from "./footer"
import {translateComponent} from "../utils/translation"

const BackgroundImg = styled.div`
  // background: url(${props => props.src}) no-repeat, linear-gradient(to top, rgba(243, 143, 143, 0), rgba(200, 229, 255, 0.9));
`;

const Container = styled.div``;

export default translateComponent(({ activeTabIndex, children }) => (
  <Container>
    <Header activeTabIndex={activeTabIndex} />
    <BackgroundImg src={cloud}>
      {children}
    </BackgroundImg>
    <Footer />
  </Container>
))