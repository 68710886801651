import styled from "@emotion/styled";
import { TranslatedLink } from "../../utils/translation"


export default styled(TranslatedLink)`
  font-size: 14px;
  margin-right: 25px;
  line-height: 1.57;
  display: inline-block;
  
  &:hover {
    color: #f38f8f;
  }
  
  &.active {
    color: #f38f8f;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      height: 2px;
      width: 100%;
      border-radius: 1px;
      background: #f38f8f;
      bottom: -3px;
    }
  }
`;
