import React from "react";
import styled from "@emotion/styled";
import Layout from "../components/layout";
import translate from "../utils/translation";

const Container = styled.div`
  max-width: 609px;
  padding: 100px 0;
`;

export default () => <Layout aboutIndex={0}>
  <Container>
    <h1>{translate("pageNotFound")}</h1>
  </Container>
</Layout>;